pre code.hljs {
    display: block;
    overflow-x: auto;
    padding: 12px 20px !important;
    font-size: 12px !important;
    line-height: 2 !important;
    font-weight: 400 !important;
}

code.hljs {
    padding: 6px 20px !important;
}

.hljs {
    color: #383a42;
    color: #383a42;
    background: #fff !important;
}

.hljs-comment,
.hljs-quote {
    color: #a0a1a7;
    font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
    color: #255abd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
    color: #e45649;
}

.hljs-literal {
    color: #0184bb;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta .hljs-string,
.hljs-regexp,
.hljs-string {
    color: #a720e5;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
    color: #1d6b91;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
    color: #d45118;
}

.hljs-built_in,
.hljs-class .hljs-title,
.hljs-title.class_ {
    color: #1d6b91;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: 700;
}

.hljs-link {
    text-decoration: underline;
}

pre code.hljs {
    display: block;
    overflow-x: auto;
    padding: 1em;
}

code.hljs {
    padding: 3px 5px;
}

.hljs {
    color: #383a42;
    background: #fafafa;
}

.hljs-comment,
.hljs-quote {
    color: #a0a1a7;
    font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
    color: #255abd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
    color: #e45649;
}

.hljs-literal {
    color: #0184bb;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta .hljs-string,
.hljs-regexp,
.hljs-string {
    color: #a720e5;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
    color: #1d6b91;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
    color: #d45118;
}

.hljs-built_in,
.hljs-class .hljs-title,
.hljs-title.class_ {
    color: #1d6b91;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: 700;
}

.hljs-link {
    text-decoration: underline;
}
